/* eslint-disable no-unused-vars */
import { fromJS, Map } from "immutable";

import {
    SET_GNSS_USER_CORRECTION_SERVICES,
    SET_SYNC_STATUS_GNSS_USER_CORRECTION_SERVICES
} from "../constants/actions/actions";

import {
    setGnssUserCorrectionServicesToState
} from "../sharedFunctions/gnssUserCorrectionServices/stateUtils";

import { idle } from "../constants/status";

const initialState = fromJS({
    gnssUserCorrectionServicesSyncStatus: idle,
    uploading: false,
    searchTerm: "",
    items: {
        gnss_ntrip_access_data: [],
        gnss_point_perfect_access_data: [],
        gnss_user_correction_services: []
    },
});

export function gnssUserCorrectionServices(state = initialState, action) {
    switch (action.type) {
        case SET_GNSS_USER_CORRECTION_SERVICES:
            return setGnssUserCorrectionServicesToState(state, action)
        case SET_SYNC_STATUS_GNSS_USER_CORRECTION_SERVICES:
            return state.set("gnssUserCorrectionServicesSyncStatus", action.payload);
        default:
            return state
    }   
}