import i18next from '../../../../../../i18n';

i18next.on('languageChanged init', () => {
    init()
})

const t = (textCode, options) => {
    return i18next.t(textCode, options);
}

export const schema = {};

const init = () => {
    schema.type = "object"
    schema.properties = {
        "header": {
            "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.header.txtTitle', {ns: 'screens_private'}),
            "type": "object",
            "properties": {
                "enabled": {
                    "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.header.enabled.txtTitle', {ns: 'screens_private'}),
                    "description": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.header.enabled.txtDescription', {ns: 'screens_private'}),
                    "type": "boolean"
                },
                "template": {
                    "type": "array",
                    "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.header.template.txtTitle', {ns: 'screens_private'}),
                    "description": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.header.template.txtDescription', {ns: 'screens_private'}),
                    "items": {
                        "type": "string"
                    }
                }
            },
            "dependencies": {
                "enabled": {
                    "oneOf": [
                        {
                            "properties": {
                                "enabled": {
                                    "const": true
                                },
                                "template": {
                                    "required": true
                                }
                            }
                        }
                    ]
                }
            }
        },
        "body": {
            "title": "Datenbereich",
            "type": "object",
            "properties": {
                "format": {
                    "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.format.txtTitle', {ns: 'screens_private'}),
                    "description": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.format.txtDescription', {ns: 'screens_private'}),
                    "type": "string"
                },
                "delimiter": {
                    "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.delimiter.txtTitle', {ns: 'screens_private'}),
                    "description": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.delimiter.txtDescription', {ns: 'screens_private'}),
                    "type": "string"
                },
                "header": {
                    "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.header.txtTitle', {ns: 'screens_private'}),
                    "description": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.header.txtDescription', {ns: 'screens_private'}),
                    "type": "array",
                    "items": {
                        "type": "string"
                    }
                    
                },
                "columns": {
                    "type": "array",
                    "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.columns.txtTitle', {ns: 'screens_private'}),
                    "items": {
                        "type": "object",
                        "properties": {
                            "field": {
                                "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.columns.items.field.txtTitle', {ns: 'screens_private'}),
                                "description": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.columns.items.field.txtDescription', {ns: 'screens_private'}),
                                "type": "string",
                                "examples": [
                                    "%id",
                                    "%timestamp",
                                    "%component_name",
                                    "%x_coordinate",
                                    "%y_coordinate",
                                    "%z_coordinate",
                                    "%epsg_code",
                                    "%latitude",
                                    "%longitude",
                                    "%altitude",
                                    "%with_image",
                                    "%layer_id",
                                    "%layer_uuid",
                                    "%layer_type",
                                    "%layer_description",
                                    "%layer_color"
                                ]
                            },
                            "start": {
                                "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.columns.items.start.txtTitle', {ns: 'screens_private'}),
                                "description": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.columns.items.start.txtDescription', {ns: 'screens_private'}),
                                "type": "integer"
                            },
                            "width": {
                                "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.columns.items.width.txtTitle', {ns: 'screens_private'}),
                                "description": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.columns.items.width.txtDescription', {ns: 'screens_private'}),
                                "type": "integer"
                            },
                            "optional": {
                                "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.columns.items.optional.txtTitle', {ns: 'screens_private'}),
                                "description": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.columns.items.optional.txtDescription', {ns: 'screens_private'}),
                                "type": "boolean"
                            },
                            "constant": {
                                "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.columns.items.constant.txtTitle', {ns: 'screens_private'}),
                                "description": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.body.columns.items.constant.txtDescription', {ns: 'screens_private'}),
                                "type": "boolean"
                            }
                        },
                        "required": [
                            "field"
                        ]
                    }
                }
            },
            "required": [
                "format",
                "delimiter",
                "header",
                "columns"
            ]
        },
        "footer": {
            "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.footer.txtTitle', {ns: 'screens_private'}),
            "type": "object",
            "properties": {
                "enabled": {
                    "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.footer.enabled.txtTitle', {ns: 'screens_private'}),
                    "description": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.footer.enabled.txtDescription', {ns: 'screens_private'}),
                    "type": "boolean"
                },
                "template": {
                    "type": "array",
                    "title": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.footer.template.txtTitle', {ns: 'screens_private'}),
                    "description": t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.TemplateSchema.footer.template.txtDescription', {ns: 'screens_private'}),
                    "items": {
                        "type": "string"
                    }
                }
            },
            "required": [
                "enabled",
                "template"
            ]
        }
    }
    schema.required = [
        "header",
        "body",
        "footer"
    ]
}

