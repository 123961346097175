/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Spinner from "../../../atoms/Spinner";

import { idle, success } from "../../../constants/status";
import { isAdmin } from "../../../sharedFunctions/user";

import { 
    syncMapLayers,
    syncMapLayersToProjectAssignments,
    updateMapLayer, 
    createMapLayer,
    deleteMapLayer,
    createMapLayerToProjectAssignment,
    deleteMapLayerToProjectAssignment
} from "../../../actions/map_layer"

import MapLayerUploadDialog from "../../../containers/dialogs/MapLayerUploadDialog";

import MapLayersTable from "./MapLayersTable";
import MapLayersHeader from "./MapLayersHeader"

const propTypes = {
    isAdmin: PropTypes.bool,
    syncMapLayers: PropTypes.func,
    syncMapLayersToProjectAssignments: PropTypes.func,
    updateMapLayer: PropTypes.func,
    createMapLayer: PropTypes.func,
    deleteMapLayer: PropTypes.func,
    createMapLayerToProjectAssignment: PropTypes.func,
    deleteMapLayerToProjectAssignment: PropTypes.func,
    syncStatusMapLayers: PropTypes.string,
    syncStatusMapLayersToProjectAssignment: PropTypes.string,
    data: PropTypes.object,
    projects: PropTypes.object,
    mapLayerAssignments: PropTypes.object,
    uploading: PropTypes.bool
};

const MapLayers = ({
    isAdmin,
    syncMapLayers,
    updateMapLayer,
    createMapLayer,
    deleteMapLayer,
    syncMapLayersToProjectAssignments,
    createMapLayerToProjectAssignment,
    deleteMapLayerToProjectAssignment,
    syncStatusMapLayers,
    syncStatusMapLayersToProjectAssignment,
    data,
    projects,
    mapLayerAssignments,
    uploading
}) => {
    const [createModalOpen, setCreateModalOpen] = React.useState(false);

    React.useEffect(() => {
        if (syncStatusMapLayers === idle) {
          syncMapLayers();
        }

        if (syncStatusMapLayersToProjectAssignment === idle) {
            syncMapLayersToProjectAssignments();
          }
      }, [
        syncStatusMapLayers,
        syncStatusMapLayersToProjectAssignment]);
    
    const closeModal = () => {
        setCreateModalOpen(false)
    }

    const onCreateClicked = () => {
        setCreateModalOpen(true)
    }

    const onUpdateClicked = (mapLayerList) => {
        for (const key in mapLayerList) {
            updateMapLayer(mapLayerList[key])
        }
    }

    const onDeleteClicked = (mapLayer) => {
        deleteMapLayer(mapLayer)
    }

    const createNewMapLayer = (params) => {
        createMapLayer(params)
    }

    const onCreateMapLayerToProjectAssignmentClicked = (selectedProject, mapLayer) => {
        createMapLayerToProjectAssignment(selectedProject, mapLayer)
    }

    const onDeleteMapLayerToProjectAssignmentClicked = (projectId, mapLayerUuid) => {
        deleteMapLayerToProjectAssignment(projectId, mapLayerUuid)
    }

    const onMultiDeleteMapLayerToProjectAssignmentClicked = (toDeleteMap) => {

        toDeleteMap.forEach((item) => {
            for(const mapLayerUuid in item) {
                item[mapLayerUuid].forEach((project) => {
                    deleteMapLayerToProjectAssignment(project.id, mapLayerUuid)
                })
            }
        })
    }
    
    return (
        <>  
             {createModalOpen &&
                showCreateMapLayerDialog(
                    createModalOpen,
                    closeModal,
                    createNewMapLayer
                )}
            <MapLayersHeader 
                uploading={uploading}
                isAdmin={isAdmin} 
                setCreateModalOpen={() => onCreateClicked()} />
            <MapLayersTable 
                isAdmin={isAdmin} 
                data={data} 
                projects={projects}
                mapLayerAssignments={mapLayerAssignments}
                updateMapLayer={onUpdateClicked}
                deleteMapLayer={onDeleteClicked} 
                createMapLayerToProjectAssignment={onCreateMapLayerToProjectAssignmentClicked}
                deleteMapLayerToProjectAssignment={onDeleteMapLayerToProjectAssignmentClicked}
                multiDeleteMapLayerToProjectAssignment={onMultiDeleteMapLayerToProjectAssignmentClicked}
            />
        </>
    )
}

function showCreateMapLayerDialog(
    createModalOpen,
    closeModal,
    createNewMapLayer
) {
    return <MapLayerUploadDialog 
        modalOpen={createModalOpen}
        onCloseModal={() => closeModal()}
        maxSize={10}
        acceptFileTypes={"application/json, application/geo+json, application/zip, image/tiff"}
        acceptedFileExtensions={["zip", "tif", "tiff", "geojson", "json"]}
        onPositiveButtonClicked={createNewMapLayer}
        onNegativeButtonClicked={() => closeModal()}
    />
}

function dispatchToProps(dispatch) {
    return bindActionCreators(
      {
        syncMapLayers,
        updateMapLayer,
        createMapLayer,
        deleteMapLayer,
        syncMapLayersToProjectAssignments,
        createMapLayerToProjectAssignment,
        deleteMapLayerToProjectAssignment
      },
      dispatch
    );
}

function stateToProps(state, ownProps) {
    const uploading = state.getIn([
        "mapLayers",
        "uploading"
    ]);

    const syncStatusMapLayers = state.getIn([
        "mapLayers",
        "mapLayersSyncStatus",
    ]);

    const syncStatusMapLayersToProjectAssignment = state.getIn([
        "mapLayers",
        "mapLayersToProjectAssignmentSyncStatus"
      ])

    const data = state.getIn([
        "mapLayers",
        "items"
    ]).toJS();

    const projects = state.getIn([
        "projects",
        "items"
    ])

    const mapLayerAssignments = {}

    data.mapLayersToProjectAssignment.forEach((item) => {

        if(!([item.map_layer_uuid] in mapLayerAssignments)) {
            mapLayerAssignments[item.map_layer_uuid] = []
        }

        const project = projects
            .filter((project) => project.get("id") === item.project_id)
            .toJS()
        
        if (project !== null && Object.keys(project).length > 0) {
            mapLayerAssignments[item.map_layer_uuid].push(
                Object.values(project)[0]
            )
        }
    })

    return {
        syncStatusMapLayers,
        syncStatusMapLayersToProjectAssignment,
        isAdmin: isAdmin(state.getIn(["user", "organization_role_groups"]), true),
        data,
        projects,
        mapLayerAssignments,
        uploading
    }
}

MapLayers.propTypes = propTypes;
export default connect(
  stateToProps,
  dispatchToProps
)(MapLayers);