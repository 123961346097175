/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";


import "../styles.css";

const propTypes = {
  t: PropTypes.func,
  executeConfirmAccountDeletion: PropTypes.func,
  changeOptionalText: PropTypes.func,
  optionalText: PropTypes.string
};

const Content = ({
  t,
  executeConfirmAccountDeletion,
  optionalText,
  changeOptionalText
}) => {
  return (
    <>
        <Typography
            variant="body1"
            component="div"
            align='center'
            sx={{ marginBottom: "2rem", fontSize: "1.5rem" }}
        >
            {t('ConfirmAccountDeletion.txtConfirmAccountDeletionBody', {ns: 'screens_private'})}
        </Typography>
        <TextField
            fullWidth={true}
            margin="none"
            label={t('ConfirmAccountDeletion.textFieldReasonPlaceholder', {ns: 'screens_private'})}
            multiline={true}
            onChange={changeOptionalText}
            placeholder={t('ConfirmAccountDeletion.textFieldReasonLabel', {ns: 'screens_private'})}
            required={true}
            sx={{ marginBottom: "2.4rem" }}
            minRows="5"
            variant="outlined"
            value={optionalText}
        />
        <Button
            size="medium"
            variant="contained"
            color="error"
            onClick={() => executeConfirmAccountDeletion()}
        >
            {t('ConfirmAccountDeletion.bntDeleteAccount', {ns: 'screens_private'})}
        </Button>
    </>
  );
};


Content.propTypes = propTypes;
export default Content;
