/* eslint-disable no-unused-vars */
import { fromJS, Map } from "immutable";

import {
    SET_CUSTOM_FILE_FORMATS,
    SET_SYNC_STATUS_CUSTOM_FILE_FORMATS
} from "../constants/actions/actions";

import {
    setCustomFileFormatsToState
} from "../sharedFunctions/customFileFormat/stateUtils";

import { idle } from "../constants/status";

const initialState = fromJS({
    customFileFormatSyncStatus: idle,
    uploading: false,
    searchTerm: "",
    items: {
        custom_file_formats: []
    },
});

export function customFileFormats(state = initialState, action) {
    switch (action.type) {
        case SET_CUSTOM_FILE_FORMATS:
            return setCustomFileFormatsToState(state, action)
        case SET_SYNC_STATUS_CUSTOM_FILE_FORMATS:
            return state.set("customFileFormatSyncStatus", action.payload);
        default:
            return state
    }   
}