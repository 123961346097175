/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";

import {
    SET_CUSTOM_FILE_FORMATS,
    SET_SYNC_STATUS_CUSTOM_FILE_FORMATS
} from "../constants/actions/actions";

import API from "../lib/api";
import HTTP from "../lib/http";
import { getOrgaId } from "../sharedFunctions/organization";

import { fail, pending, success } from "../constants/status";

export const setCustomFileFormats = createAction(SET_CUSTOM_FILE_FORMATS)
export const setSyncStatusCustomFileFormats = createAction(SET_SYNC_STATUS_CUSTOM_FILE_FORMATS)

export function syncCustomFileFormats() {
    return async(dispatch, getState) => {
        dispatch(setSyncStatusCustomFileFormats(pending));

        const orgaId = getOrgaId(getState());

        await HTTP.get(API.organizations.customFileFormat.indexForOrganization(orgaId))
            .then(function (response) {
                // success
                dispatch(setSyncStatusCustomFileFormats(success));
                dispatch(setCustomFileFormats(response.data))
            }).catch(function (error) {
                // error
                dispatch(setSyncStatusCustomFileFormats(fail));
            })
    }
}