/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid2";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Typography from "@mui/material/Typography";

import classNames from "classnames";

import * as styles from "../../../../../styles/styles";

const propTypes = {
  history: PropTypes.object,
  mapLayers: PropTypes.any,
  classes: PropTypes.object,
};

const overrideStyles = (theme) => ({
  root: {
    borderRadius: "10px",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    backgroundColor: styles.white,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: styles.darkBlueMedium,
    //boxShadow: styles.boxShadowListElement,
    boxShadow: "none",
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
});

const ConfiguratorMapLayerCard = ({ history, mapLayers, classes }) => {
  const { t } = useTranslation();

  const renderCardContent = () => {
    return (
      <div>
        <Typography
          variant="h5"
          component="div"
          sx={{ marginBottom: "1.0rem", fontSize: "2.5rem" }}
        >
          {t('Configurator.ConfiguratorCards.ConfiguratorMapLayerCard.txtTitle', {ns: 'screens_private'})}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{ marginBottom: "2rem", fontSize: "1.5rem" }}
        >
          <div style={{ minHeight: "80px"}}>
            {t('Configurator.ConfiguratorCards.ConfiguratorMapLayerCard.txtBody', {ns: 'screens_private'})}
          </div>
          
        </Typography>
        <Grid container direction="row" size={12}>
          <Grid
            container
            direction="row"
            size={12}
            sx={{ marginBottom: "0.35rem" }}
          >
            <Grid style={{ width: "30px"}}>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontSize: "1.5rem", fontWeight: "600" }}
              >
                {mapLayers.length > 0
                  ? `${mapLayers.length}`
                  : 0}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontSize: "1.5rem" }}
              >
                {t('Configurator.ConfiguratorCards.ConfiguratorMapLayerCard.txtAvailableEntries.key', {count: mapLayers.length, ns: 'screens_private'})}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Grid>
      <Card className={classNames(classes.root)}>
        <CardActionArea
          onClick={() => {
            history.push(`/configurator/map-layers`);
          }}
        >
          <CardContent>{renderCardContent()}</CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

function stateToProps(state) {
  const mapLayers = state.getIn([
    "mapLayers",
    "items",
    "mapLayers",
  ]);

  return {
    mapLayers,
  };
}

ConfiguratorMapLayerCard.propTypes = propTypes;
export default connect(
  stateToProps,
  null
)(withStyles(overrideStyles, { withTheme: true })(ConfiguratorMapLayerCard));
