import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import classNames from "classnames";
import withStyles from "@mui/styles/withStyles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SuperVisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import FolderIcon from "@mui/icons-material/Folder";
import ArchiveIcon from "@mui/icons-material/Archive";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Wordmark from "../../atoms/Wordmark";
import Logo from "../../atoms/Logo/Logo";

import * as styles from "../../styles/styles";

const propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  history: PropTypes.object,
  theme: PropTypes.object.isRequired,
  subscriptionExpired: PropTypes.bool,
  isProjectRestrictedSingle: PropTypes.bool,
};

const overrideStyles = (theme) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  drawer: {
    width: styles.drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    backgroundColor: "#1F2633",
    width: styles.drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#1F2633",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  list: {
    flex: 1,
    padding: 0,
  },
  wrapper: {
    backgroundColor: "#181F29",
  },
  listItem: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 24,
      paddingRight: 24,
      height: "7.2rem",
    },
    "&$disabled": {
      opacity: 1,
      pointerEvents: "none",
    },
    "&$selected, &$selected:hover, &$selected:focus": {
      backgroundColor: styles.primary,
    },
    "&:hover": {
      backgroundColor: "#252E3D",
    },
  },
  toolbarDrawerOpen: {
    justifyContent: "flex-start‚",
  },
  toolbarDrawerClosed: {
    justifyContent: "center",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    ...theme.mixins.toolbar,
  },
  last: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  icon: {
    color: "#fff",
  },
  divider: {
    backgroundColor: "#FFFFFF1A",
  },
  selected: {},
  disabled: {},
  svg_icon: {
    display: "inline-block",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    flexShrink: 0,
    userSelect: "none",
  },
  iconDrawerOpen: {},
  textPrimary: {
    opacity: 0.66,
  },
  textSecondary: {
    opacity: 0.45,
  },
  text: {
    color: styles.white,
    letterSpacing: "0.06rem",
    "&:hover": {
      color: styles.white,
      opacity: 1,
    },
  },
  textSelected: {
    color: styles.white,
    opacity: 1,
  },
  textDrawerClosed: {
    visibility: "hidden",
  },
  logo: {
    paddingLeft: "1.1rem",
  },
  logoRight: {
    paddingRight: "1.8rem",
  },
  textTop: {
    paddingTop: "0.7rem",
  },
});

const netpipeIconWidth = 4;
const netpipeIconHeight = 4;
const netpipeWordmarkWidth = 7.448;
const netpipeWordmarkHeight = 2;

// Outsource into a separate component.
const renderNetpipeLogo = (classes, expanded) => (
  <ListItemButton
    className={classNames(classes.listItem, classes.wrapper, classes.logo)}
    onClick={() => window.open("https://www.netpipe.io/", "_blank")}
  >
    <ListItemIcon
      className={classNames(classes.logoRight, {
        [classes.iconDrawerOpen]: expanded,
      })}
    >
      <Logo width={netpipeIconWidth} height={netpipeIconHeight} />
    </ListItemIcon>
    <ListItemText
      className={classNames(classes.textTop, {
        [classes.textDrawerClosed]: !expanded,
      })}
      primary={
        <Wordmark width={netpipeWordmarkWidth} height={netpipeWordmarkHeight} />
      }
    />
  </ListItemButton>
);

const renderProjectListEntry = (t, classes, expanded, selected, history) => (
  <>
    <ListItemButton
      onClick={() => history.push(`/projects`)}
      className={classNames(classes.listItem, {
        [classes.selected]:
          selected.includes("projects") &&
          !selected.includes("projects/archived"),
      })}
      selected={
        selected.includes("projects") && !selected.includes("projects/archived")
      }
    >
      <ListItemIcon
        className={classNames(classes.icon, {
          [classes.iconDrawerOpen]: expanded,
        })}
      >
        <FolderIcon />
      </ListItemIcon>
      <ListItemText
        className={classNames(classes.text, classes.textPrimary, {
          [classes.textDrawerClosed]: !expanded,
          [classes.textSelected]:
            selected.includes("projects") &&
            !selected.includes("projects/archived"),
        })}
        primaryTypographyProps={{ variant: "subtitle2" }}
        primary={t('Sidebar.listItemTextProjects', {ns: 'components'})}
      />
    </ListItemButton>
    <Divider className={classes.divider} />
  </>
);

const renderArchivedProjectListEntry = (
  t,
  classes,
  expanded,
  selected,
  history
) => (
  <>
    <ListItemButton
      onClick={() => history.push(`/projects/archived`)}
      className={classNames(classes.listItem, {
        [classes.selected]: selected.includes("projects/archived"),
      })}
      selected={selected.includes("projects/archived")}
    >
      <ListItemIcon
        className={classNames(classes.icon, {
          [classes.iconDrawerOpen]: expanded,
        })}
      >
        <ArchiveIcon />
      </ListItemIcon>
      <ListItemText
        className={classNames(classes.text, classes.textPrimary, {
          [classes.textDrawerClosed]: !expanded,
          [classes.textSelected]: selected.includes("projects/archived"),
        })}
        primaryTypographyProps={{ variant: "subtitle2" }}
        primary={t('Sidebar.listItemTextArchive', {ns: 'components'})}
      />
    </ListItemButton>
    <Divider className={classes.divider} />
  </>
);

const renderConfguratorListEntry = (t, classes, expanded, selected, history) => (
  <ListItemButton
    onClick={() => history.push(`/configurator`)}
    className={classNames(classes.listItem, {
      [classes.selected]: selected.includes("configurator"),
    })}
    selected={selected.includes("configurator")}
  >
    <ListItemIcon
      className={classNames(classes.icon, {
        [classes.iconDrawerOpen]: expanded,
      })}
    >
      <BuildCircleIcon />
    </ListItemIcon>
    <ListItemText
      className={classNames(classes.text, classes.textPrimary, {
        [classes.textDrawerClosed]: !expanded,
        [classes.textSelected]: selected.includes("configurator"),
      })}
      primaryTypographyProps={{ variant: "subtitle2" }}
      primary={t('Sidebar.listItemTextConfigurator', {ns: 'components'})}
    />
  </ListItemButton>
);

const renderFaqEntry = (t, classes, expanded, selected) => (
  <ListItemButton
    className={classNames(classes.listItem, {
      [classes.selected]: selected === "faq",
    })}
    onClick={() => window.open("https://www.netpipe.io/faq", "_blank")}
    selected={selected === "faq"}
  >
    <ListItemIcon
      className={classNames(classes.icon, {
        [classes.iconDrawerOpen]: expanded,
      })}
    >
      <InfoIcon />
    </ListItemIcon>
    <ListItemText
      className={classNames(classes.text, classes.textSecondary, {
        [classes.textDrawerClosed]: !expanded,
        [classes.textSelected]: selected === "faq",
      })}
      primaryTypographyProps={{ variant: "subtitle2" }}
      primary={t('Sidebar.listItemTextFaq', {ns: 'components'})}
    />
  </ListItemButton>
);

const renderOrganizationEntry = (
  t,
  classes,
  expanded,
  selected,
  history,
  isAdmin
) =>
  isAdmin && (
    <>
      <ListItemButton
        className={classNames(classes.listItem, {
          [classes.selected]: selected.includes("organization"),
        })}
        onClick={() => history.push(`/organization`)}
        selected={selected.includes("organization")}
      >
        <ListItemIcon
          className={classNames(classes.icon, {
            [classes.iconDrawerOpen]: expanded,
          })}
        >
          <SuperVisedUserCircleIcon />
        </ListItemIcon>
        <ListItemText
          className={classNames(classes.text, classes.textSecondary, {
            [classes.textDrawerClosed]: !expanded,
            [classes.textSelected]: selected.includes("organization"),
          })}
          primaryTypographyProps={{ variant: "subtitle2" }}
          primary={t('Sidebar.listItemTextUser', {ns: 'components'})}
        />
      </ListItemButton>
    </>
  );

const renderSettingsEntry = (t, classes, expanded, selected, history) => (
  <ListItemButton
    className={classNames(classes.listItem, {
      [classes.selected]:
        selected.includes("settings") && !selected.includes("user_settings"),
    })}
    onClick={() => history.push(`/settings`)}
    selected={
      selected.includes("settings") && !selected.includes("user_settings")
    }
  >
    <ListItemIcon
      className={classNames(classes.icon, {
        [classes.iconDrawerOpen]: expanded,
      })}
    >
      <SettingsIcon />
    </ListItemIcon>
    <ListItemText
      className={classNames(classes.text, classes.textSecondary, {
        [classes.textDrawerClosed]: !expanded,
        [classes.textSelected]:
          selected.includes("settings") && !selected.includes("user_settings"),
      })}
      primaryTypographyProps={{ variant: "subtitle2" }}
      primary={t('Sidebar.listItemTextSettings', {ns: 'components'})}
    />
  </ListItemButton>
);

const Sidebar = ({
  classes,
  isAdmin,
  isProjectRestrictedSingle,
  expanded,
  onToggle,
  selected,
  history,
  subscriptionExpired,
}) => {
  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    onToggle(true);
  };

  const handleDrawerClose = () => {
    onToggle(false);
  };

  const renderDrawerMenu = () => {
    return (
      <div>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: expanded,
            [classes.drawerClose]: !expanded,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: expanded,
              [classes.drawerClose]: !expanded,
            }),
          }}
          open={expanded}
        >
          <List className={classes.list}>
            {
              /* Render the NetpipeLogo as the first entry in the sidebar.
            This entry is not clickable.*/
              renderNetpipeLogo(classes, expanded)
            }
            <Divider className={classes.divider} />
            {
              /* The ProjektList Entry routes to a page where all Projects for
            User are shown in a List. Projects with the Status archived are excluded 
            here.*/
              renderProjectListEntry(t, classes, expanded, selected, history)
            }
            {
              /* Show a Page where all Projects with the Status archived
            are shown.*/
              !isProjectRestrictedSingle &&
                renderArchivedProjectListEntry(
                  t,
                  classes,
                  expanded,
                  selected,
                  history
                )
            }
            {
              /* Show a Page where all component catalogs are shown. */
              renderConfguratorListEntry(t, classes, expanded, selected, history)
            }
            <Divider className={classes.divider} />

            <div className={classNames(classes.last)}>
              <Divider className={classes.divider} />

              {
                /* This Entry routes to a page where some common Q&A is listed
              Warning: The content is outdated!*/
                renderFaqEntry(t, classes, expanded, selected, history)
              }

              <Divider className={classes.divider} />

              {
                /* If the current User has the Admin role, he has access to some internal
              Settings of his organization. */
                renderOrganizationEntry(
                  t,
                  classes,
                  expanded,
                  selected,
                  history,
                  isAdmin
                )
              }

              <Divider className={classes.divider} />

              {
                /* Access the settings page for the current User.*/
                renderSettingsEntry(t, classes, expanded, selected, history)
              }

              <Divider className={classes.divider} />
            </div>
          </List>
          <div
            className={classNames(classes.toolbar, classes.wrapper, {
              [classes.toolbarDrawerOpen]: expanded,
              [classes.toolbarDrawerClosed]: !expanded,
            })}
          >
            {expanded ? (
              <IconButton onClick={handleDrawerClose} size="large">
                <ChevronLeftIcon className={classes.icon} />
              </IconButton>
            ) : (
              <IconButton onClick={handleDrawerOpen} size="large">
                <ChevronRightIcon className={classes.icon} />
              </IconButton>
            )}
          </div>
        </Drawer>
      </div>
    );
  };

  return !subscriptionExpired && renderDrawerMenu();
};

Sidebar.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(Sidebar);
