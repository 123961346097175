const exampleFromTemplateSchema = {
    "body": {
        "format": "KOR", 
        "header": [], 
        "columns": [
            {"field": "%timestamp", "start": 9, "width": 14, "optional": false}, 
            {"field": "%component_name", "start": 23, "width": 14, "optional": false}, 
            {"field": "X", "start": 38, "width": 2, "constant": true}, 
            {"field": "%x_coordinate", "start": 40, "width": 10, "optional": false}, 
            {"field": "Y", "start": 54, "width": 2, "constant": true}, 
            {"field": "%y_coordinate", "start": 56, "width": 11, "optional": false}, 
            {"field": "Z", "start": 70, "width": 2, "constant": true}, 
            {"field": "%z_coordinate", "start": 72, "width": 6, "optional": false}
        ], 
        "delimiter": ""
    }, 
    "footer": {
        "enabled": true, 
        "template": [
            "*", "************************************************************************************************"
        ]
    }, 
    "header": {
        "enabled": true, 
        "template": [
            "************************************************************************************************", 
            "**** Organisation: %organization_name", 
            "**** Projektnr: %job_order", 
            "**** Projekt: %project_title", 
            "**** Ersteller: %user_name", 
            "**** Datum: %project_updated", 
            "**** Datei: %file_name", 
            "**** EPSG-Code: %epsg_code", 
            "**** Device: %device_name", 
            "************************************************************************************************"
        ]
    }
}

export default exampleFromTemplateSchema