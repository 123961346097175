/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LandingPage from '../../../../components/LandingPage';
import EnterEmail from './EnterEmail'
import InformationAccountDeletion from './InformationRequestAccountDeletion'

import { requestAccountDeletionNotSignedIn, resetUserFlags } from '../../../../actions/user';

const propTypes = {
  requestAccountDeletionNotSignedIn: PropTypes.func,
  resetUserFlags: PropTypes.func,
  user: PropTypes.object,
}

const AccountDeletion = ({
  requestAccountDeletionNotSignedIn,
  resetUserFlags,
  user,
}) => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    page: 1,
    email: '',
  });

  const [age, setAge] = useState(0);

  const requestAccountDeletion = () => {
    requestAccountDeletionNotSignedIn(state.email, age);
    setState({ ...state, page: 2 });
  }

  const renderPage = () => {
    switch (state.page) {
      case 1:
        return <EnterEmail
          email={state.email}
          age={age}
          requestAccountDeletion={requestAccountDeletion}
          onChange={event => setState({ ...state, email: event.target.value.trim() })} 
          onChangeAge={value => setAge(value)} />
      case 2:
        return <InformationAccountDeletion
          resetUserFlags={resetUserFlags}
          user={user.toJS()} />
      default:
        break;
    }
  }

  return <LandingPage
    headline={t('AccountDeletion.RequestAccountDeletion.txtTitle', {ns: 'screens_public'})}>
    {renderPage()}
  </LandingPage>
}

function stateToProps(state) {
  const user = state.get('user');
  return {
    user
  }
}

function dispatchToProps(dispatch) {
  return bindActionCreators({
    requestAccountDeletionNotSignedIn,
    resetUserFlags,
  }, dispatch)
}

AccountDeletion.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(AccountDeletion);