/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl";
import withStyles from "@mui/styles/withStyles";
import UndoIcon from "@mui/icons-material/Undo";
import { createRoot } from "react-dom/client";
import * as turf from "@turf/turf";

import CustomModal from "../../../../../components/CustomModal";
import CustomImageView from "../../../../../components/CustomImageView";
import MeasurementList from "../MeasurementList";
import MapPopUp from "../MapPopUp";

import { GERMANY, UNDEFINED, checkCountry } from "./ecs/utils/country_check";
import { loadCustomImageList } from "./ecs/utils/image_loader";

import "./styles.css";
import "mapbox-gl/dist/mapbox-gl.css";

import {
  SYSTEM_CLICK,
  SYSTEM_HOVER,
  SYSTEM_RENDER,
} from "../../../../../constants/ecs";
import { generateMapEntities } from "./ecs/mapEntitiesGenerator";
import { createGeoJsonPoints } from "./ecs/utils/geojsonutils";
import SystemClick from "./ecs/systems/systemClick";
import SystemHover from "./ecs/systems/systemHover";
import SystemRender from "./ecs/systems/systemRender";
import { BASEMAP_DE_VECTOR_STYLE, DEFAULT_STYLE } from "./ecs/utils/base_styles";



const propTypes = {
  classes: PropTypes.object,
  project: PropTypes.object,
  measurements: PropTypes.object,
  referencePoints: PropTypes.object,
  referencePointsPerLayer: PropTypes.array,
  measurementsPerLayer: PropTypes.array,
  volumes: PropTypes.object,
  mediaFiles: PropTypes.object,
  topographicPoints: PropTypes.object,
  topographicPointsPerLayer: PropTypes.array,
  componentCatalogItems: PropTypes.object,
  gnssPointsPerLayer: PropTypes.array,
  gnssRefPointsPerLayer: PropTypes.array,
  gnssLinesPerLayer: PropTypes.array,
  gnssPolygonPerLayer: PropTypes.array,
  gnssPoints: PropTypes.array,
  gnssImages: PropTypes.array
};

const overrideStyles = (theme) => ({
  zoomOutMapIcon: {
    position: "absolute",
    width: "3.4rem",
    height: "3.4rem",
    borderRadius: "0.6rem",
    border: "2px solid rgba(0,0,0,0.2)",
    top: "22rem",
    right: "0.8rem",
    padding: "0.4rem",
    backgroundColor: "white",
    zIndex: 800,
    cursor: "auto",
    "&:hover": {
      backgroundColor: "#F4F4F1",
      cursor: "pointer",
    },
  },
});

const ProjectMap = ({
  classes,
  project,
  measurements,
  referencePoints,
  referencePointsPerLayer,
  measurementsPerLayer,
  volumes,
  mediaFiles,
  topographicPoints,
  topographicPointsPerLayer,
  componentCatalogItems,
  gnssPointsPerLayer,
  gnssRefPointsPerLayer,
  gnssLinesPerLayer,
  gnssPolygonPerLayer,
  gnssPoints,
  gnssImages
}) => {
  const rawPointData = Object.values(measurements)
    .concat(
      Object.values(topographicPoints), 
      Object.values(gnssPoints),
      Object.values(gnssImages))
  const newPoints = createGeoJsonPoints(rawPointData, {});

  var newCountry = UNDEFINED
  if (newPoints.features.length > 0) {
    const pt = turf.point(newPoints.features[0].geometry.coordinates)
    newCountry = checkCountry(pt)
  } 

  const mapContainerRef = React.useRef(null); // Ref for the map container div
  const [currentHighlight, setCurrentHighlight] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [points, setPoints] = React.useState(newPoints);
  const [bbox, setBbox] = React.useState(turf.bbox(points));
  const [modalOpen, setModalOpen] = React.useState(false);
  const [measurementLineAvailabe, setMeasurementLineAvailabe] = React.useState(measurements.length > 0);
  const [gnssDataAvailable, setGnssDataAvailable] = React.useState(gnssPoints.length > 0);
  const [volumeAvailable, setVolumeAvailable] = React.useState(volumes.length > 0);
  const [systems, setSystems] = React.useState([]);
  const [mapEntity, setMapEntity] = React.useState(null);
  const [map, setMap] = React.useState(null);
  const [country, setCountry] = React.useState(newCountry);
  const [cameraImg, setCameraImg] = React.useState(null);
  const [popUp, setPopUp] = React.useState(null);
  const [prevProjectId, setPrevProjectId] = React.useState(project.id);

  ////////////////////////////////////////
  // ComponentDidMount/ComponentDidUnmount
  React.useEffect(() => {
    if (!mapContainerRef.current) return;

    const style = country === GERMANY ? BASEMAP_DE_VECTOR_STYLE : DEFAULT_STYLE
    let newMap;

    if (measurementLineAvailabe || gnssDataAvailable) {
      newMap = new mapboxgl.Map({
        trackResize: true,
        bounds: bbox,
        fitBoundsOptions: { padding: 30 },
        container: mapContainerRef.current,
        style: style,
        accessToken:
          "pk.eyJ1IjoiZ3JvYmVydCIsImEiOiJja3d2ejAwN2MweGdxMnFtaThpbGRxbzNmIn0.p83TeA_nVV8WiMEpNFYnoQ",
      });

      loadCustomImageList(newMap)

    } else {
      newMap = new mapboxgl.Map({
        trackResize: true,
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v9",
        accessToken:
          "pk.eyJ1IjoiZ3JvYmVydCIsImEiOiJja3d2ejAwN2MweGdxMnFtaThpbGRxbzNmIn0.p83TeA_nVV8WiMEpNFYnoQ",
      });
    }

    setMap((old) => {
      return newMap
    });

    newMap.on("load", async () => {
      newMap.resize();
      newMap.addControl(
        new mapboxgl.NavigationControl({ visualizePitch: true }),
        "top-right"
      );
      newMap.addControl(new mapboxgl.ScaleControl());
      newMap.addControl(
        new mapboxgl.FullscreenControl({
          container: document.getElementById("project-map-container"),
        })
      );
      newMap.getCanvas().style.cursor = "auto";

      if (measurementLineAvailabe || gnssDataAvailable) {
        const newMapEntity = generateMapEntities(
          country,
          referencePointsPerLayer,
          measurementsPerLayer,
          topographicPointsPerLayer,
          gnssPointsPerLayer,
          gnssRefPointsPerLayer,
          gnssLinesPerLayer,
          gnssPolygonPerLayer,
          gnssImages
        );

        const systemClick = new SystemClick({
          map: newMap,
          onShowPopUp: onShowPopUp,
          onHighlightUpdatedListener: onHighlightUpdatedListener,
        });
        const systemHover = new SystemHover({
          map: newMap,
        });
        const systemRender = new SystemRender({ 
          map: newMap 
        });

        const systems = [systemClick, systemHover, systemRender];

        systems.forEach((system) => {
          system.run(newMapEntity);
        });

        setSystems(systems)
        setMapEntity(newMapEntity);

      } else {
        if (project.place.latLng) {
          newMap.jumpTo({
            center: project.place.latLng,
            zoom: 19,
          });
        }
      }
    });

    return () => {
      if (newMap) {
        newMap.remove();
      }
    }
  }, [mapContainerRef]);
  // ComponentDidMount/ComponentDidUnmount
  ////////////////////////////////////////
  

  /////////////////////
  // ComponentDidUpdate
  React.useEffect(() => {
    if (prevProjectId === project.id) {
      return;
    }

    systems.forEach((system) => {
      if (system.systemType === SYSTEM_RENDER)
        system.clearComponents(mapEntity);
    });

    const newMapEntity = generateMapEntities(
      country,
      referencePointsPerLayer,
      measurementsPerLayer,
      topographicPointsPerLayer,
      gnssPointsPerLayer,
      gnssRefPointsPerLayer,
      gnssLinesPerLayer,
      gnssPolygonPerLayer,
      gnssImages
    );

    systems.forEach((system) => {
      system.run(newMapEntity);
    });

    const rawPointData = Object.values(measurements)
      .concat(
        Object.values(topographicPoints), 
        Object.values(gnssPoints),
        Object.values(gnssImages))

    const points = createGeoJsonPoints(rawPointData, {});
    map.fitBounds(turf.bbox(points), { padding: 30 });
    popUp && popUp.remove();
    
    setPoints(points);
    setBbox(turf.bbox(points));
    setMapEntity(newMapEntity);
    setCurrentHighlight(null);
    setPopUp(null);
    setPrevProjectId(project.id);
  }, [project.id])
  // ComponentDidUpdate
  /////////////////////
  

  const addPopup = (map, viewComponent, coordinates) => {
    const placeholder = document.createElement("div");
    const root = createRoot(placeholder);
    root.render(viewComponent);
    const newPopUp = new mapboxgl.Popup({
      className: "custom-mapboxgl-popup-content",
      closeButton: true,
      closeOnClick: true,
    }).setDOMContent(placeholder)
      .setLngLat(coordinates)
      .addTo(map);

    setPopUp(newPopUp);
  }

  const onShowPopUp = (map, event, component, id, coordinates) => {
    console.log(id)
    addPopup(
      map,
      <MapPopUp
        onClick={(image) => {
          setModalOpen(true);
          setImage(image);
        }}
        id={id}
        feature={event.features[0]}
      />,
      coordinates
    );
  }

  const onHighlightUpdatedListener = (id, component) => {
    setCurrentHighlight(id);
  }

  const setFeatureStateOnListClicked= (id, componentType) => {
    systems.forEach((system) => {
      if (system.systemType === SYSTEM_CLICK)
        system.onGlobalHighlightEvent(mapEntity, id, componentType);
    });
  }

  const onListClick = (dataItem) => {
    var id = "";
    if ("id" in dataItem) {
      id = dataItem.id
    } else if ("uuid" in dataItem) {
      id = dataItem.uuid
    }

    setFeatureStateOnListClicked(id, dataItem.type);
    map.flyTo({
      center: points.features.filter((feature) => feature.id === id)[0]
        .geometry.coordinates,
      zoom: 24,
    });

    popUp !== null && popUp.remove();
    if (currentHighlight === id) {
      setCurrentHighlight(null);
    } else {
      setCurrentHighlight(id);
    }
  }

  const fitBounds = (event) => {
    event.stopPropagation();
    map.fitBounds(bbox, { padding: 30 });
  }

  const onHoverListEnter = (id, componentType) => {
    if (map != null && map.loaded()) {
      systems.forEach((system) => {
        if (system.systemType === SYSTEM_HOVER) {
          system.onGlobalHoverListEnterEvent(mapEntity, id, componentType);
        }
      });
    }
  }

  const onHoverListLeave = (id, componentType) => {
    if (map != null && map.loaded()) {
      systems.forEach((system) => {
        if (system.systemType === SYSTEM_HOVER)
          system.onGlobalHoverListLeaveEvent(mapEntity, id, componentType);
      });
    }
  }

  return (
    <>
      {modalOpen && (
        <CustomModal
          showCloseButton={true}
          open={modalOpen}
          image={image}
          onClose={() => {
            setModalOpen(false);
            setImage(null);
          }}
          render={() => (
            <CustomImageView>
              <img src={image} className="image-modal-image" alt={`Bild`} />
            </CustomImageView>
          )}
        />
      )}
      <div
        style={{
          backgroundColor: "white",
          height: "45vh",
          width: "100%",
        }}
        id="project-map-container"
        ref={mapContainerRef}
      />
      <UndoIcon
        onClick={fitBounds}
        classes={{ root: classes.zoomOutMapIcon }}
      />
      <MeasurementList
        measurmentListClick={currentHighlight}
        onHoverListEnter={onHoverListEnter}
        onHoverListLeave={onHoverListLeave}
        onListClick={(measurement) => onListClick(measurement)}
        referencePoints={referencePoints}
        referencePointsPerLayer={referencePointsPerLayer}
        measurementsPerLayer={measurementsPerLayer}
        topographicPointsPerLayer={topographicPointsPerLayer}
        volumes={volumes}
        mediaFiles={mediaFiles}
        componentCatalogItems={componentCatalogItems}
        gnssPointsPerLayer={gnssPointsPerLayer}
        gnssRefPointsPerLayer={gnssRefPointsPerLayer}
        gnssLinesPerLayer={gnssLinesPerLayer}
        gnssPolygonPerLayer={gnssPolygonPerLayer}
        gnssImages={gnssImages}
      />
    </>
  );
}

ProjectMap.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(ProjectMap);
