/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Confirmation from "../../../../../components/dialogs/Confirmation";

import "./styles.css";

const propTypes = {
  requestAccountDeletion: PropTypes.func,
  onChange: PropTypes.func,
  onChangeAge: PropTypes.func,
  email: PropTypes.string,
  age: PropTypes.number
};
const pattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EnterEmail = ({ 
  requestAccountDeletion, 
  onChange, 
  onChangeAge,
  email,
  age
 }) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = React.useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  /**
   * This function is called when the user presses a key while
   * the EnterEmail component is focused. If the pressed key is
   * the Enter key, it opens the confirmation modal.
   *
   * @param {Object} event - The event object of the key press event.
   */
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      openModal();
    }
  };

  const validEmail = (email) => {
    return new RegExp(pattern).test(email);
  }

  const renderMessage = () => {
   return t('AccountDeletion.RequestAccountDeletion.EnterEmail.txtInformation', {ns: 'screens_public'})
  }

  const continueRequestAccountDeletion = () => {
    requestAccountDeletion()
  }

  return (
    <>
    {modalOpen &&
        renderModalContent(
          t,
          modalOpen,
          closeModal,
          continueRequestAccountDeletion
        )}
    <Typography
      variant='body1'
      align='center'>
      {renderMessage()}
    </Typography>
      {/*H O N E Y P O T - F I E L D - A G E */}
      <TextField
        variant="standard"
        autoFocus={false}
        fullWidth={true}
        required={true}
        value={age}
        placeholder={t('registration.RegistrationDialog.PageOne.textFieldAgePlaceholder', {ns: 'components'})}
        name="age"
        type="number"
        label={t('registration.RegistrationDialog.PageOne.textFieldAgeLabel', {ns: 'components'})}
        sx={{
          opacity: 0,
          position: "absolute",
          top: 0,
          left: 0,
          height: 0,
          width: 0,
          zIndex: -1,
        }}
        onChange={onChangeAge}
        slotProps={{
          input: {
            inputProps: { tabIndex: -1 },
          },
  
          inputLabel: {
            shrink: true,
          }
        }} />
      <TextField
        variant="standard"
        fullWidth={true}
        label={t('AccountDeletion.RequestAccountDeletion.EnterEmail.textFieldEmailLabel', {ns: 'screens_public'})}
        type="email"
        margin="normal"
        value={email}
        onKeyDown={onKeyDown}
        onChange={onChange}
      />
      <div className="enter-email-button-wrapper">
        <Link to="/login" style={{ textDecoration: "none" }}>
          <Button size="medium" variant="contained">
          {t('button.btnBack', {ns: 'common'})}
          </Button>
        </Link>
        <Button
          size="medium"
          variant="contained"
          color="error"
          disabled={!validEmail(email)}
          onClick={() => openModal()}
        >
          {t('button.btnRequestAccountDeltion', {ns: 'common'})}
        </Button>
      </div>
    </>
  );
};

function renderModalContent(
  t,
  modalOpen,
  closeModal,
  continueRequestAccountDeletion
) {
  return <Confirmation
    highlightedText={""}
    headline={t('Confirmation.requestAccountDeletion.txtHeadline', {ns: 'common'})}
    leftSideText={t('Confirmation.requestAccountDeletion.txtLeftSideText', {ns: 'common'})}
    modalOpen={modalOpen}
    onClose={() => closeModal()}
    onClick={continueRequestAccountDeletion}
    rightSideText={t('Confirmation.requestAccountDeletion.txtRightSideText', {ns: 'common'})}
    textButton={t('Confirmation.requestAccountDeletion.txtTextButton', {ns: 'common'})}
  />
}

EnterEmail.propTypes = propTypes;
export default EnterEmail;