/* eslint-disable no-unused-vars */
const ITEMS = "items";
const CUSTOM_FILE_FORMATS = "customFileFormats";

/**
 * Result of a CustomFileFormat Sync-Request
 * 
 * {
 *   "custom_file_format_list": [
 *       {
 *           "uuid": "e18a71e2-8ff1-4f54-8e2c-0f6be8c4f1b0",
 *           "organization_id": 2,  
 *           "json_template": {},
 *           "updated_at": "2024-04-05T07:46:58Z",
 *           "inserted_at": "2024-04-05T07:46:58Z"
 *       },
 *   ]
 * }
 */
export function setCustomFileFormatsToState(state, action) {
    if (action.payload && Object.keys(action.payload).length > 0) {

      const newItems = {
        custom_file_formats: action.payload.custom_file_format_list
      }

      return state.mergeIn([ITEMS], newItems);

    } else {
        return state;
    }
}