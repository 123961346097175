import { withTheme } from '@rjsf/core';

//import { Form } from '@rjsf/mui';
import { Theme } from '@rjsf/mui'

import validator from '@rjsf/validator-ajv8';
import { schema } from './template_schema'
import uiSchema from './template_ui_schema'
import exampleFromTemplateSchema from './example_from_template_schema'

const FormWithMuiTemplate = withTheme(Theme)

const propTypes = {
}

const CustomFileFormatItem = () => {

    const onChange = (change) => {
        console.log(change.formData)
    }

    console.log(schema)

    return (
        <div style={{ overflow: 'auto', height: '100vh' }}>
            <div style={{ margin: `2rem` }}>
                <FormWithMuiTemplate 
                    schema={schema}
                    uiSchema={uiSchema}
                    formData={exampleFromTemplateSchema} 
                    validator={validator}
                    onChange={onChange}
                />
            </div>
        </div>
        
    )
}

CustomFileFormatItem.propTypes = propTypes

export default CustomFileFormatItem;