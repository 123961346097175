/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import CustomDialog from "../../CustomDialog";
import ButtonGroup from "../../ButtonGroup";

const propTypes = {
  children: PropTypes.array,
  headline: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  highlightedText: PropTypes.string,
  leftSideText: PropTypes.string,
  rightSideText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool,
  textButton: PropTypes.string,
};

const InfoDialog = ({
  children,
  headline,
  highlightedText = "",
  leftSideText = "",
  modalOpen,
  onClose,
  onClick,
  rightSideText = "",
  textButton,
}) => {
  const { t } = useTranslation();

  const renderText = () => {
    if (children)
      return (
        <Typography variant="body1" color="textPrimary">
          {children}
        </Typography>
      );

    return (
      <>
        {/* Have to use <span> instead of <Typography> here, otherwise it will breaek
       not nicely */}
        <span className="dialog-text">{leftSideText}</span>
        <span className="dialog-text-highlight">{` ${highlightedText} `}</span>
        <span className="dialog-text">{rightSideText}</span>
      </>
    );
  };

  return (
    <CustomDialog
      onClick={onClick}
      open={modalOpen}
      renderTitle={() => {
        return <DialogTitle id="alert-dialog-title">{headline}</DialogTitle>;
      }}
      renderContent={() => {
        return (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {renderText()}
            </DialogContentText>
          </DialogContent>
        );
      }}
      renderButtonGroup={(onClick, onClose) => {
        return (
          <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
            <ButtonGroup
              spacingTop={true}
              align="right"
              leftButtonHidden={true}
              rightButtonText={textButton}
              rightButtonClick={onClick}
            />
          </DialogActions>
        );
      }}
    />
  );
}

InfoDialog.propTypes = propTypes;
export default InfoDialog;