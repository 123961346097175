import i18next from '../../../../i18n';
import { MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  COMPONENTS,
  MATERIALS,
  DIMENSIONS,
  PIPE_DEFINITION,
} from "../../../../constants/catalogCategoryMapping";

import { EDIT_KEY, DELETE_KEY } from "../../../../constants/contextMenuEntries";


i18next.on('languageChanged init', () => {
    init()
})

const t = (textCode, options) => {
  return i18next.t(textCode, options);
}

const rowActions = {
  [COMPONENTS]: [EDIT_KEY, DELETE_KEY],
  [MATERIALS]: [EDIT_KEY, DELETE_KEY],
  [DIMENSIONS]: [EDIT_KEY, DELETE_KEY],
  [PIPE_DEFINITION]: [DELETE_KEY],
  };

const rowActionLabels = {}

const init = () => {
  rowActionLabels[EDIT_KEY] = t('ComponentCatalog.ComponentCatalogActionMenuItems.txtMenuItemEdit', {ns: 'screens_private'})
  rowActionLabels[DELETE_KEY] = t('ComponentCatalog.ComponentCatalogActionMenuItems.txtMenuItemDelete', {ns: 'screens_private'})
}
 

function renderRowActionMenuItems(
  row, 
  closeMenu, 
  dataComponent, 
  onEditClicked, 
  onDeleteClicked
) {
  const menuItems = [];

  if (rowActions[dataComponent].includes(EDIT_KEY)) {
    menuItems.push(renderRowActionEditMenuItem(row, closeMenu, dataComponent, onEditClicked));
  }
  if (rowActions[dataComponent].includes(DELETE_KEY)) {
    menuItems.push(renderRowActionDeleteMenuItem(row, closeMenu, dataComponent, onDeleteClicked));
  }
  return menuItems;
}


function renderRowActionEditMenuItem(
  row, 
  closeMenu, 
  dataComponent, 
  onEditClicked
){
  return (
    <MenuItem
      key={1}
      onClick={() => {
        onEditClicked(row, dataComponent);
        closeMenu();
      }}
    >
      <EditIcon /> {rowActionLabels[EDIT_KEY]}
    </MenuItem>
  );
}


function renderRowActionDeleteMenuItem(
  row, 
  closeMenu, 
  dataComponent,
  onDeleteClicked
){
  return (
    <MenuItem
      key={2}
      onClick={() => {
        onDeleteClicked(row, dataComponent);
        closeMenu();
      }}
    >
      <DeleteIcon /> {rowActionLabels[DELETE_KEY]}
    </MenuItem>
  );
}


export function componentCatalogActionMenuItems({
  row,
  closeMenu,
  dataComponent,
  onEditClicked,
  onDeleteClicked,
}) {
  return renderRowActionMenuItems(
    row, 
    closeMenu, 
    dataComponent, 
    onEditClicked, 
    onDeleteClicked);
}
