import i18next from '../i18n';
import {
  red500,
  pink500,
  purple500,
  deeppurple500,
  indigo500,
  blue500,
  lightblue500,
  cyan500,
  teal500,
  green500,
  lightgreen500,
  lime500,
  yellow500,
  amber500,
  orange500,
  deeporange500,
  darkBlueMediumDark
} from "../styles/styles";

export const COLOR_MAP = {
  red: red500,
  pink: pink500,
  purple: purple500,
  deep_purple: deeppurple500,
  indigo: indigo500,
  blue: blue500,
  light_blue: lightblue500,
  cyan: cyan500,
  teal: teal500,
  green: green500,
  light_green: lightgreen500,
  lime: lime500,
  yellow: yellow500,
  amber: amber500,
  orange: orange500,
  deep_orange: deeporange500,
  unknown: darkBlueMediumDark
};

export const COLOR_MAP_NAMES = {}

i18next.on('languageChanged init', () => {
  init()
})

const t = (textCode, options) => {
  return i18next.t(textCode, options);
}

const init = () => {
  COLOR_MAP_NAMES.red = t('colorMapping.txtRed', {ns: 'constants'})
  COLOR_MAP_NAMES.pink = t('colorMapping.txtPink', {ns: 'constants'})
  COLOR_MAP_NAMES.purple = t('colorMapping.txtPurple', {ns: 'constants'})
  COLOR_MAP_NAMES.deep_purple = t('colorMapping.txtDeepPurple', {ns: 'constants'})
  COLOR_MAP_NAMES.indigo = t('colorMapping.txtIndigo', {ns: 'constants'})
  COLOR_MAP_NAMES.blue = t('colorMapping.txtBlue', {ns: 'constants'})
  COLOR_MAP_NAMES.light_blue = t('colorMapping.txtLightBlue', {ns: 'constants'})
  COLOR_MAP_NAMES.cyan = t('colorMapping.txtCyan', {ns: 'constants'})
  COLOR_MAP_NAMES.teal = t('colorMapping.txtTeal', {ns: 'constants'})
  COLOR_MAP_NAMES.green = t('colorMapping.txtGreen', {ns: 'constants'})
  COLOR_MAP_NAMES.light_green = t('colorMapping.txtLightGreen', {ns: 'constants'})
  COLOR_MAP_NAMES.lime = t('colorMapping.txtLime', {ns: 'constants'})
  COLOR_MAP_NAMES.yellow = t('colorMapping.txtYellow', {ns: 'constants'})
  COLOR_MAP_NAMES.amber = t('colorMapping.txtAmber', {ns: 'constants'})
  COLOR_MAP_NAMES.orange = t('colorMapping.txtOrange', {ns: 'constants'})
  COLOR_MAP_NAMES.deep_orange = t('colorMapping.txtDeepOrange', {ns: 'constants'})
}