/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import queryString from "qs";

import { confirmAccountDeletionNotSignedIn } from "../../../../actions/user";

import { confirmAccountDeletionSuccess } from "../../../../constants/snackbars";

import Content from './Content';
import LandingPage from '../../../../components/LandingPage';

import "./styles.css";

const propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  confirmAccountDeletionNotSignedIn: PropTypes.func,
};

const ValidateAccountDeletion = ({
  location,
  history,
  confirmAccountDeletionNotSignedIn,
}) => {
  const { t } = useTranslation();

  const [state, setState] = useState({
    optionalText: "",
  });

  let urlParams = queryString.parse(location && location.search);
  const token = urlParams["?account_deletion_token"];

  const changeOptionalText = (event) => {
    const description = event.target.value;
    setState({ ...state, optionalText: description });
  };

  const executeConfirmAccountDeletion = () => {
    (async () => {
      const executionState = await confirmAccountDeletionNotSignedIn(
        token,
        state.optionalText
      );

      if (executionState == confirmAccountDeletionSuccess) {
        history.replace(`/login`);
      }
    })();
  };

  return (
   <LandingPage
       headline={t('AccountDeletion.ValidateAccountDeletion.txtTitle', {ns: 'screens_public'})}>
       <Content 
            t={t}
            executeConfirmAccountDeletion={executeConfirmAccountDeletion}
            optionalText={state.optionalText}
            changeOptionalText={changeOptionalText} />
     </LandingPage>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
        confirmAccountDeletionNotSignedIn,
    },
    dispatch
  );
}


ValidateAccountDeletion.propTypes = propTypes;
export default connect(null, dispatchToProps)(ValidateAccountDeletion);
