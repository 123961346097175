import { COMPONENT_TYPE_LABEL } from "../../../../../../../constants/ecs";
import { GNSS_POINT, TOPOGRAPHIC_POINT, REFERENCE_POINT, MEASURE_POINT, GNSS_REF_POINT, LABEL } from "../../../../../../../constants/pointLookupTable";
import { GERMANY } from "../utils/country_check";

export default class ComponentLabels {
  constructor(params) {
    this.componentType = COMPONENT_TYPE_LABEL;
    this.componentName = LABEL;
    this.name = params.name;
    this.forEntityType = params.forEntityType;
    this.country = params.country;

    this.textFont = this.country === GERMANY ? ["Noto Sans Regular"] : ["Open Sans Semibold", "Arial Unicode MS Bold"]

    this.source = {
      type: "geojson",
      data: params.data,
      promoteId: "uuid",
    };

    var idField = "";
    this.textOffset = [0, 0.5]
    
    if (this.forEntityType === MEASURE_POINT || 
      this.forEntityType === REFERENCE_POINT || 
      this.forEntityType === TOPOGRAPHIC_POINT
    ) {
      idField = "id_user"

    } else if (this.forEntityType === GNSS_POINT || 
      this.forEntityType === GNSS_REF_POINT
    ) {
      idField = "user_id"
    }

    if (this.forEntityType == GNSS_REF_POINT) {
      this.textOffset = [1, 0]
    }

    this.layer = {
      id: this.name,
      type: "symbol",
      source: this.name,
      layout: {
        // get the title name from the source's "title" property
        "text-field": ["get", idField],
        "text-font": this.textFont,
        "text-offset": this.textOffset,
        "text-anchor": "top",
        'icon-allow-overlap': true,
        "text-allow-overlap": true,
      },
    };
  }


  getComponentType() {
    return this.componentType;
  }

  getComponentName() {
    return this.componentName;
  }
}
