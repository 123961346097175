/* eslint-disable no-unused-vars */
import React, {useState} from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Grid from "@mui/material/Grid2";

import ConfiguratorHeader from "./ConfiguratorHeader";
import ConfiguratorComponentCard from "./ConfiguratorCards/ConfiguratorComponentCard";
import ConfiguratorTemplateCard from "./ConfiguratorCards/ConfiguratorTemplateCard";
import ConfiguratorMapLayerCard from "./ConfiguratorCards/ConfiguratorMapLayerCard";
import ConfiguratorGnssCorrectionServiceCard from "./ConfiguratorCards/ConfiguratorGnssCorrectionServiceCard";
import ConfiguratorCustomFileFormatCard from "./ConfiguratorCards/ConfiguratorCustomFileFormatCard";

import InfoDialog from "../../../components/dialogs/InfoDialog";

import { idle } from "../../../constants/status";

import { syncMeasureLayerConfigTemplates } from "../../../actions/measureLayerConfigTemplates";
import { syncComponentCatalogs } from "../../../actions/componentCatalogs";
import { 
  syncMapLayers, 
  syncMapLayersToProjectAssignments 
} from "../../../actions/map_layer";
import { syncCustomFileFormats } from "../../../actions/customFileFormat"
import { syncGnssUserCorrectionServices } from "../../../actions/gnssUserCorrectionServices"

const propTypes = {
  history: PropTypes.object,
  syncStatusComponentCatalogs: PropTypes.string,
  syncStatusMeasureLayerTemplates: PropTypes.string,
  syncStatusMapLayers: PropTypes.string,
  syncStatusMapLayersToProjectAssignment: PropTypes.string,
  syncStatusCustomFileFormats: PropTypes.string,
  syncStatusGnssUserCorrectionServices: PropTypes.string,
  syncMeasureLayerConfigTemplates: PropTypes.func,
  syncComponentCatalogs: PropTypes.func,
  syncMapLayers: PropTypes.func,
  syncMapLayersToProjectAssignments: PropTypes.func,
  syncCustomFileFormats: PropTypes.func,
  syncGnssUserCorrectionServices: PropTypes.func
};

const Configurator = ({
  history,
  syncStatusComponentCatalogs,
  syncStatusMeasureLayerTemplates,
  syncStatusMapLayers,
  syncStatusMapLayersToProjectAssignment,
  syncStatusCustomFileFormats,
  syncStatusGnssUserCorrectionServices,
  syncMeasureLayerConfigTemplates,
  syncComponentCatalogs,
  syncMapLayers,
  syncMapLayersToProjectAssignments,
  syncCustomFileFormats,
  syncGnssUserCorrectionServices
}) => {
  React.useEffect(() => {
    if (syncStatusMeasureLayerTemplates === idle) {
      syncMeasureLayerConfigTemplates();
    }
    if (syncStatusComponentCatalogs === idle) {
      syncComponentCatalogs();
    }
    if (syncStatusMapLayers === idle) {
      syncMapLayers();
    }
    if (syncStatusMapLayersToProjectAssignment === idle) {
      syncMapLayersToProjectAssignments();
    }
    if (syncStatusCustomFileFormats === idle) {
      syncCustomFileFormats();
    }
    if (syncStatusGnssUserCorrectionServices === idle) {
      syncGnssUserCorrectionServices();
    }
  }, [
    syncStatusMeasureLayerTemplates, 
    syncStatusComponentCatalogs, 
    syncStatusMapLayers,
    syncStatusMapLayersToProjectAssignment,
    syncStatusCustomFileFormats,
    syncStatusGnssUserCorrectionServices]);

  const [modalOpen, setModalOpen] = useState({
    open: false,
    params: {
      headline: "",
      leftSideText: "",
      rightSideText: "",
      textButton: ""
    }
  })

  const showInfoDialog = (infoDialogParams) => {
    setModalOpen({
      open: true,
      params: infoDialogParams
    })
  }

  return (
    <div>
      {modalOpen && renderInfoDialog(modalOpen, setModalOpen)}

      <ConfiguratorHeader />
      <div style={{ overflow: "auto", maxHeight: "calc(100vh - 7.2rem)" }}>
        <div style={{ margin: "2.5rem" }}>
          <Grid container direction="row" spacing={4}>
            <Grid size={{xs:12, md:6}}>
              <ConfiguratorTemplateCard 
                key="template-card" 
                history={history}
              />
            </Grid>
            <Grid size={{xs:12, md:6}}>
              <ConfiguratorComponentCard 
                key="component-card" 
                history={history} 
              />
            </Grid>
            <Grid size={{xs:12, md:6}}>
              <ConfiguratorMapLayerCard 
                key="map-layer-card" 
                history={history} 
              />
            </Grid>
            <Grid size={{xs:12, md:6}}>
              <ConfiguratorCustomFileFormatCard 
                key="custom-file-format-card" 
                history={history}
                showInfoDialog={showInfoDialog} 
              />
            </Grid>
            <Grid size={{xs:12, md:6}}>
              <ConfiguratorGnssCorrectionServiceCard 
                key="gnss-correction-service-card" 
                history={history}
                showInfoDialog={showInfoDialog} 
              />
            </Grid>
          </Grid>
        </div>
      </div>
      
    </div>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      syncMeasureLayerConfigTemplates,
      syncComponentCatalogs,
      syncMapLayers,
      syncMapLayersToProjectAssignments,
      syncCustomFileFormats,
      syncGnssUserCorrectionServices
    },
    dispatch
  );
}

function renderInfoDialog(modalOpen, setModalOpen) {
  return (
    <InfoDialog
      headline={modalOpen.params.headline}
      leftSideText={modalOpen.params.leftSideText}
      rightSideText={modalOpen.params.rightSideText}
      textButton={modalOpen.params.textButton}
      modalOpen={modalOpen.open}
      onClick={() => {
        setModalOpen(false);
      }}
      onClose={() => {
        setModalOpen(false);
      }}
    />
  );
}

function stateToProps(state) {
  const syncStatusComponentCatalogs = state.getIn([
    "componentCatalogs",
    "componentCatalogSyncStatus",
  ]);

  const syncStatusMeasureLayerTemplates = state.getIn([
    "measureLayerConfigTemplates",
    "measureLayerConfigTemplateSyncStatus",
  ]);

  const syncStatusMapLayers = state.getIn([
    "mapLayers",
    "mapLayersSyncStatus",
  ]);

  const syncStatusMapLayersToProjectAssignment = state.getIn([
    "mapLayers",
    "mapLayersToProjectAssignmentSyncStatus"
  ])

  const syncStatusCustomFileFormats = state.getIn([
    "customFileFormats",
    "customFileFormatSyncStatus"
  ])

  const syncStatusGnssUserCorrectionServices = state.getIn([
    "gnssUserCorrectionServices",
    "gnssUserCorrectionServicesSyncStatus"
  ])

  return {
    syncStatusComponentCatalogs,
    syncStatusMeasureLayerTemplates,
    syncStatusMapLayers,
    syncStatusMapLayersToProjectAssignment,
    syncStatusCustomFileFormats,
    syncStatusGnssUserCorrectionServices
  };
}

Configurator.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(Configurator);
