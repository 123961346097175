/* eslint-disable no-unused-vars */
const ITEMS = "items";
const GNSS_USER_CORRECTION_SERVICES = "gnss_user_correction_services";
const GNSS_NTRIP_ACCESS_DATA = "gnss_ntrip_access_data";
const GNSS_POINT_PERFECT_ACCESS_DATA = "gnss_point_perfect_access_data";

/**
 * Result of a CustomFileFormat Sync-Request
 * 
 * {
 *   "gnss_ntrip_access_data": [
 *     {
 *       "caster": "www.sapos-be-ntrip.de",
 *       "inserted_at": "2025-02-18T15:48:46Z",
 *       "mount_point": "VRS_3_4G_BE",
 *       "organization_id": 1,
 *       "password": "",
 *       "port": 2101,
 *       "updated_at": "2025-02-28T14:12:27Z",
 *       "username": "",
 *       "uuid": ""
 *     }
 *   ],
 *   "gnss_point_perfect_access_data": [],
 *   "gnss_user_correction_services": [
 *     {
 *       "gnss_correction_service_type": "ntrip",
 *       "gnss_correction_service_uuid": "",
 *       "inserted_at": "2025-02-18T15:48:46Z",
 *       "is_default": true,
 *       "is_used": false,
 *       "organization_id": -1,
 *       "updated_at": "2025-02-28T14:12:28Z",
 *       "user_id": -1,
 *       "uuid": ""
 *     }
 *   ]
 * }
 */
export function setGnssUserCorrectionServicesToState(state, action) {
    if (action.payload && Object.keys(action.payload).length > 0) {

      const newItems = {
        gnss_ntrip_access_data: action.payload.gnss_ntrip_access_data,
        gnss_point_perfect_access_data: action.payload.gnss_point_perfect_access_data,
        gnss_user_correction_services: action.payload.gnss_user_correction_services
      }

      return state.mergeIn([ITEMS], newItems);

    } else {
        return state;
    }
}