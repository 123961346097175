/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { batch } from "react-redux";
import { createAction } from "redux-actions";
import {
  EDIT_USER,
  FORGOT_PASSWORD_STATUS,
  LOGOUT,
  RESET_USER_FLAGS,
  SET_PASSWORD_STATUS,
  SET_USER,
  REQUEST_ACCOUNT_DELETION_STATUS,
} from "../constants/actions/actions";
import {
  USER_UPDATE_EVENT,
  USER_STATUS_EVENT,
} from "../constants/channelevents";
import {
  deleteAvatarFail,
  deleteAvatarSuccess,
  editUserDetailsFail,
  editUserDetailsSuccess,
  uploadAvatarFail,
  uploadAvatarSuccess,
  requestAccountDeletionFail,
  requestAccountDeletionSuccess,
  confirmAccountDeletionSuccess,
  confirmAccountDeletionFail,
} from "../constants/snackbars";
import { fail, pending, success } from "../constants/status";
import API from "../lib/api";
import history from "../lib/history";

import HTTP from "../lib/http";
import { initApp } from "../lib/init";
import { setMeasurements } from "./measurements";
import { setMeasurementLines } from "./measurementlines";
import { setMeasurementSegments } from "./measurementsegments";
import { setMediaFiles } from "./mediaFiles";
import { setVolumes } from "./volumes";
import { setMeasureLayerConfigs } from "./measure_layer_configs";
import { setTopographicPoints } from "./topographic_points";
import { setGnssData } from "./gnss_data";
import {
  setStandardProjectSyncStatus,
  setArchivedProjectSyncStatus,
} from "./app";

import {
  removeOrganization,
  setOrganizations,
  setRemovedFromOrganization,
} from "./organizations";
import { setProjects, setProjectStatus } from "./projects";
import { renderSnackbar } from "./snackbars";

export const setUser = createAction(SET_USER);
export const editUser = createAction(EDIT_USER);
export const resetUserFlags = createAction(RESET_USER_FLAGS);
const forgotPasswordStatus = createAction(FORGOT_PASSWORD_STATUS);
const setPasswordStatus = createAction(SET_PASSWORD_STATUS);
const requestAccountDeletionStatus = createAction(REQUEST_ACCOUNT_DELETION_STATUS);
const logout = createAction(LOGOUT);

export const CableActions = (response, ac, store) => {
  switch (response.event) {
    case USER_UPDATE_EVENT:
      store.dispatch(editUser(response.payload));
      break;

    case USER_STATUS_EVENT:
      if (response.payload.status === "removed_from_organization") {
        store.dispatch(
          setRemovedFromOrganization(response.payload.organization_id)
        );
        store.dispatch(
          removeOrganization({ id: response.payload.organization_id })
        );
        localStorage.clear();
        store.dispatch(logout);
        history.push("/login");
      }
      break;

    default:
      break;
  }
};

export const resetPassword = (password, token) => {
  return async (dispatch, getState) => {
    dispatch(setPasswordStatus(pending));
    await HTTP.put(API.resetPassword, {
      user: {
        password,
        password_confirmation: password,
      },
      token,
    })
      .then(function (response) {
        dispatch(setPasswordStatus(success));
      })
      .catch(function (error) {
        console.log("ERROR RESET PASSWORD CONFIRM USER", error);
        dispatch(setPasswordStatus(fail));
      });
  };
};

export const forgotPassword = (email) => {
  return async (dispatch, getState) => {
    dispatch(forgotPasswordStatus(pending));
    await HTTP.post(API.resetPassword, { user: { email } })
      .then(function (response) {
        dispatch(forgotPasswordStatus(success));
      })
      .catch(function (error) {
        console.log("ERROR RESET PASSWORD USER", error);
        dispatch(forgotPasswordStatus(fail));
      });
  };
};

export const signOut = () => {
  return async (dispatch) => {
    dispatch(logout);
    localStorage.clear();
    window.location.href = "/login";
    return true;
  };
};

export const validate = () => {
  return async (dispatch) => {
    try {
      const response = await HTTP.get(API.user.validate, null);
      dispatch(setUser({ ...response.data.user, login: true }));
      dispatch(initApp());
      return response;
    } catch (error) {
      console.log("ERROR IN VALIDATING USER", error);
      return error;
    }
  };
};

export const syncRequestStandard = () => {
  return async (dispatch) => {
    dispatch(setProjectStatus(pending));
    dispatch(setStandardProjectSyncStatus(pending));

    await HTTP.post(API.sync.standard, null)
      .then(function (response) {
        batch(() => {
          dispatch(setOrganizations(response.data.data.organizations));
          dispatch(setProjects(response.data.data.projects));
          dispatch(setMeasurements(response.data.data.measurements));
          dispatch(setMeasurementLines(response.data.data.measurement_lines));
          dispatch(
            setMeasurementSegments(response.data.data.measurement_segments)
          );
          dispatch(setMediaFiles(response.data.data.media_files));
          dispatch(setVolumes(response.data.data.volumes));
          dispatch(
            setMeasureLayerConfigs(response.data.data.measure_layer_configs)
          );
          dispatch(setTopographicPoints(response.data.data.topographic_points));
          dispatch(setGnssData(response.data.data.gnss_data))
          dispatch(setProjectStatus(success));
          dispatch(setStandardProjectSyncStatus(success));
        });
      })
      .catch(function (error) {
        //dispatch(setIsSyncingFromServer(false))
        dispatch(setProjectStatus(fail));
        dispatch(setStandardProjectSyncStatus(fail));
        console.log("ERROR INIT  REQUEST", error);
      });
  };
};

export const syncRequestArchived = () => {
  return async (dispatch) => {
    dispatch(setProjectStatus(pending));
    dispatch(setArchivedProjectSyncStatus(pending));

    await HTTP.post(API.sync.archived, null)
      .then(function (response) {
        batch(() => {
          dispatch(setOrganizations(response.data.data.organizations));
          dispatch(setProjects(response.data.data.projects));
          dispatch(setMeasurements(response.data.data.measurements));
          dispatch(setMeasurementLines(response.data.data.measurement_lines));
          dispatch(
            setMeasurementSegments(response.data.data.measurement_segments)
          );
          dispatch(setMediaFiles(response.data.data.media_files));
          dispatch(setVolumes(response.data.data.volumes));
          dispatch(
            setMeasureLayerConfigs(response.data.data.measure_layer_configs)
          );
          dispatch(setTopographicPoints(response.data.data.topographic_points));
          dispatch(setGnssData(response.data.data.gnss_data))
          dispatch(setProjectStatus(success));
          dispatch(setArchivedProjectSyncStatus(success));
        });
      })
      .catch(function (error) {
        dispatch(setProjectStatus(fail));
        dispatch(setArchivedProjectSyncStatus(fail));
        console.log("ERROR INIT  REQUEST", error);
      });
  };
};

export function deleteAvatar() {
  return async (dispatch) => {
    await HTTP.delete(API.user.deleteAvatar, null)
      .then(function (response) {
        dispatch(renderSnackbar(deleteAvatarSuccess));
      })
      .catch(function (error) {
        console.log("ERROR DELETING AVATAR", error);
        dispatch(renderSnackbar(deleteAvatarFail));
      });
  };
}

export function uploadAvatar(avatar) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return async (dispatch) => {
    await HTTP.put(API.user.auth, avatar, config)
      .then(function (response) {
        dispatch(renderSnackbar(uploadAvatarSuccess));
      })
      .catch(function (error) {
        console.log("ERROR ON EDITING AVATAR", error);
        dispatch(renderSnackbar(uploadAvatarFail));
      });
  };
}

export function updateUserDetails(user) {
  return async (dispatch) => {
    await HTTP.put(API.user.auth, { user })
      .then(function (response) {
        dispatch(renderSnackbar(editUserDetailsSuccess));
      })
      .catch(function (error) {
        console.log("ERROR EDITING USER DETAILS", error);
        dispatch(renderSnackbar(editUserDetailsFail));
      });
  };
}

////////////////////////////////////////
// Account Deletion for a signed in user
export function requestAccountDeletion(organizationId) {
  return async (dispatch) => {
    await HTTP.get(
      API.organizations.users.requestAccountDeletion(organizationId)
    )
      .then(function (response) {
        dispatch(renderSnackbar(requestAccountDeletionSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(requestAccountDeletionFail));
      });
  };
}

export function confirmAccountDeletion(organizationId, token, optionalText) {
  return async (dispatch) => {
    return await HTTP.put(
      API.organizations.users.confirmAccountDeletion(organizationId),
      {
        token: token,
        optional_text: optionalText,
      }
    )
      .then(function (response) {
        dispatch(renderSnackbar(confirmAccountDeletionSuccess));
        return confirmAccountDeletionSuccess;
      })
      .catch(function (error) {
        dispatch(renderSnackbar(confirmAccountDeletionFail));
        return confirmAccountDeletionFail;
      });
  };  
}
// Account Deletion for a signed in user
////////////////////////////////////////


////////////////////////////////////////////
// Account Deletion for a NOT signed in user
export function requestAccountDeletionNotSignedIn(email, age) {
  return async (dispatch) => {
    dispatch(requestAccountDeletionStatus(pending));

    // H O N E Y P O T - C H E C K
    // If the age is greater than 0 then it is very likely 
    // that a bot has requested the account deletion. In that case we
    // don't need to send a request to the server and send directly a
    // success.
    if (age > 0) {
      dispatch(requestAccountDeletionStatus(success));
      return;
    }
    
    await HTTP.post(API.user.requestAccountDeletion, { user: { email }})
      .then(function (response) {
        dispatch(requestAccountDeletionStatus(success));
      })
      .catch(function (error) {
        dispatch(requestAccountDeletionStatus(fail));
      });
  };
}

export function confirmAccountDeletionNotSignedIn(token, optionalText) {
  return async (dispatch) => {
    return await HTTP.post(
      API.user.confirmAccountDeletion(),
      {
        token: token,
        optional_text: optionalText,
      }
    )
      .then(function (response) {
        dispatch(renderSnackbar(confirmAccountDeletionSuccess));
        return confirmAccountDeletionSuccess;
      })
      .catch(function (error) {
        dispatch(renderSnackbar(confirmAccountDeletionFail));
        return confirmAccountDeletionFail;
      });
  };
}
// Account Deletion for a NOT signed in user
////////////////////////////////////////////
