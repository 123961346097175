import { fromJS } from "immutable";
import {
  EDIT_USER,
  FORGOT_PASSWORD_STATUS,
  LOGOUT,
  RESET_USER_FLAGS,
  SET_PASSWORD_STATUS,
  SET_USER,
  REQUEST_ACCOUNT_DELETION_STATUS
} from "../constants/actions/actions";

export const initialState = fromJS({
  id: null,
  email: "",
  forgotPasswordStatus: null,
  setPasswordStatus: null,
  requestAccountDeletionStatus: null
});

export function user(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return state.merge(action.payload);

    case LOGOUT:
      return initialState;

    case EDIT_USER:
      return state.merge(action.payload);

    case SET_PASSWORD_STATUS:
      return state.set("setPasswordStatus", action.payload);

    case FORGOT_PASSWORD_STATUS:
      return state.set("forgotPasswordStatus", action.payload);

    case REQUEST_ACCOUNT_DELETION_STATUS:
      return state.set("requestAccountDeletionStatus", action.payload);

    case RESET_USER_FLAGS:
      return state
        .set("forgotPasswordStatus", null)
        .set("setPasswordStatus", null)
        .set("requestAccountDeletionStatus", null);

    default:
      return state;
  }
}
