/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";

import {
    SET_GNSS_USER_CORRECTION_SERVICES,
    SET_SYNC_STATUS_GNSS_USER_CORRECTION_SERVICES
} from "../constants/actions/actions";

import API from "../lib/api";
import HTTP from "../lib/http";
import { getOrgaId } from "../sharedFunctions/organization";

import { fail, pending, success } from "../constants/status";

export const setGnssUserCorrectionServices = createAction(SET_GNSS_USER_CORRECTION_SERVICES)
export const setSyncStatusGnssUserCorrectionServices = createAction(SET_SYNC_STATUS_GNSS_USER_CORRECTION_SERVICES)

export function syncGnssUserCorrectionServices() {
    return async(dispatch, getState) => {
        dispatch(setSyncStatusGnssUserCorrectionServices(pending));
        const orgaId = getOrgaId(getState());

        await HTTP.get(API.organizations.gnssUserCorrectionServices.indexForOrganization(orgaId))
            .then(function (response) {
                // success
                dispatch(setSyncStatusGnssUserCorrectionServices(success));
                dispatch(setGnssUserCorrectionServices(response.data))
            }).catch(function (error) {
                // error
                dispatch(setSyncStatusGnssUserCorrectionServices(fail));
            })
    }
}

