/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid2";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Typography from "@mui/material/Typography";

import classNames from "classnames";

import * as styles from "../../../../../styles/styles";

const propTypes = {
  history: PropTypes.object,
  classes: PropTypes.object,
  showInfoDialog: PropTypes.func,
  customFileFormats: PropTypes.any
};

const overrideStyles = (theme) => ({
  root: {
    borderRadius: "10px",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    backgroundColor: styles.white,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: styles.darkBlueMedium,
    //boxShadow: styles.boxShadowListElement,
    boxShadow: "none",
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
});

const ConfiguratorCustomFileFormatCard = ({ 
  history, 
  classes,
  showInfoDialog,
  customFileFormats
}) => {
  const { t } = useTranslation();

  const renderCardContent = () => {
    return (
      <div>
        <Typography
          variant="h5"
          component="div"
          sx={{ marginBottom: "1.0rem", fontSize: "2.5rem" }}
        >
          {t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.txtTitle', {ns: 'screens_private'})}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{ marginBottom: "2rem", fontSize: "1.5rem" }}
        >
          <div style={{ minHeight: "80px"}}>
            {t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.txtBody', {ns: 'screens_private'})}
          </div>
          
        </Typography>
        <Grid container direction="row" size={12}>
          <Grid
            container
            direction="row"
            size={12}
            sx={{ marginBottom: "0.35rem" }}
          >
            <Grid style={{ width: "30px"}}>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontSize: "1.5rem", fontWeight: "600" }}
              >
                {customFileFormats.length > 0
                  ? `${customFileFormats.length}`
                  : 0}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontSize: "1.5rem" }}
              >
                {t('Configurator.ConfiguratorCards.ConfiguratorCustomFileFormatCard.txtAvailableEntries.key', {count: customFileFormats.length, ns: 'screens_private'})}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Grid>
      <Card className={classNames(classes.root)}>
        <CardActionArea
          onClick={() => {
            showInfoDialog(
              {
                headline: t('dialogs.InfoDialog.ConfiguratorCustomFileFormatInfoDialog.txtHeadline', {ns: 'containers'}),
                rightSideText: t('dialogs.InfoDialog.ConfiguratorCustomFileFormatInfoDialog.txtRightSideText', {ns: 'containers'}),
                textButton: t('button.btnOk', {ns: 'common'})
              }
            )
          }}
        >
          <CardContent>{renderCardContent()}</CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

function stateToProps(state) {
  const customFileFormats = state.getIn([
    "customFileFormats",
    "items",
    "custom_file_formats"
  ])

  return {
    customFileFormats
  }
}

ConfiguratorCustomFileFormatCard.propTypes = propTypes;
export default connect(
  stateToProps,
  null
)(withStyles(overrideStyles, { withTheme: true })(ConfiguratorCustomFileFormatCard));
