import React, { useState } from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import { isAdmin } from "../../sharedFunctions/user";
import Private from "../../screens/private";
import Sidebar from "../../components/Sidebar";
import Admin from "../../screens/private/admin";

import { hasOrganizationFeatureFlag } from "../../sharedFunctions/organization";
import { PROJECT_RESTRICTED_SINGLE } from "../../constants/featureFlags";

import "./styles.css";
import * as styles from "../../styles/styles";

const propTypes = {
  history: PropTypes.object,
  admin: PropTypes.bool,
  isProjectRestrictedSingle: PropTypes.bool,
  subscriptionExpired: PropTypes.bool,
  match: PropTypes.any,
};

const MainLayout = ({
  admin,
  isProjectRestrictedSingle,
  history,
  match,
  subscriptionExpired,
}) => {
  const [expanded, setExpanded] = useState(true);

  const onToggle = (expanded) => {
    setExpanded(expanded);
  };

  const renderRoutes = (isAdmin) => {
    if (subscriptionExpired) {
      return (
        <Route
          path={"/subscription-expired"}
          component={Private.SubscriptionExpired}
        />
      );
    }

    return (
      <Switch>
        <Route
          path={"/confirmation_account_deletion"}
          exact
          component={Private.ConfirmAccountDeletion}
        />
        <Route path={"/projects"} exact component={Private.Projects} />
        {!isProjectRestrictedSingle && (
          <Route
            path={"/projects/archived"}
            exact
            component={Private.Projects}
          />
        )}
        {!isProjectRestrictedSingle && (
          <Route path={"/projects/archived/:id"} component={Private.Project} />
        )}
        <Route path={"/projects/:id"} component={Private.Project} />
        <Route path={"/settings"} exact component={Private.UserProfile} />

        {isAdmin && (
          <Route
            path={"/organization/user_settings/:id"}
            component={Admin.AdminUserProfil}
          />
        )}
        {isAdmin && (
          <Route path={"/organization"} component={Admin.UserAdministration} />
        )}

        <Route path={"/configurator"} exact component={Private.Configurator} />
        <Route
          path={"/configurator/component-catalogs"}
          exact
          component={Private.ComponentCatalogs}
        />
        <Route
          path={"/configurator/component-catalogs/:id"}
          component={Private.ComponentCatalog}
        />
        <Route
          path={"/configurator/layer-templates"}
          exact
          component={Private.MeasureLayerConfigTemplates}
        />
        <Route
          path={"/configurator/layer-templates/:id"}
          component={Private.MeasureLayerConfigTemplate}
        />
        <Route 
          path={"/configurator/map-layers"}
          component={Private.MapLayers}
        />
        <Route
          path={"/configurator/custom-file-format"}
          component={Private.CustomFileFormatItem}
        />
        <Redirect from={match.path} to={"/projects"} />
      </Switch>
    );
  };

  const setContainerMargin = () => {
    if (subscriptionExpired) return 0;
    return expanded ? styles.drawerWidth : "7.2rem";
  };

  return (
    <div style={{ flexDirection: "row" }}>
      <Sidebar
        history={history}
        isAdmin={admin}
        isProjectRestrictedSingle={isProjectRestrictedSingle}
        onToggle={onToggle.bind(this)}
        expanded={expanded}
        selected={history.location.pathname.slice(
          1,
          history.location.pathname.length
        )}
        subscriptionExpired={subscriptionExpired}
      />
      <div
        className="private-wrapper-app-containe"
        style={{
          marginLeft: setContainerMargin(),
        }}
      >
        {renderRoutes(admin)}
      </div>
    </div>
  );
};

MainLayout.propTypes = propTypes;

function stateToProps(state) {
  const isAdminResult = isAdmin(
    state.getIn(["user", "organization_role_groups"]),
    true
  );
  const isProjectRestrictedSingle = hasOrganizationFeatureFlag(
    state,
    PROJECT_RESTRICTED_SINGLE
  );

  return {
    subscriptionExpired: state.getIn(["organizations", "subscriptionExpired"]),
    admin: isAdminResult,
    isProjectRestrictedSingle,
  };
}

export default connect(stateToProps)(MainLayout);
