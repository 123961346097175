import Login from "./Login";
import Registration from "./Registration";
import ForgotPasswordDialog from "./ResetPassword/ForgotPasswordDialog";
import ResetPasswordDialog from "./ResetPassword/ResetPassword";
import ConfirmUser from "./ConfirmUser";
import RequestAccountDeletion from "./AccountDeletion/RequestAccountDeletion";
import ValidateAccountDeletion from "./AccountDeletion/ValidateAccountDeletion";

export default {
  Login,
  Registration,
  ConfirmUser,
  ForgotPasswordDialog,
  ResetPasswordDialog,
  RequestAccountDeletion,
  ValidateAccountDeletion
};
