/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { ListSubheader } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as styles from "../../../../../../styles/styles";
import ColoredCircle from "../../../../../../components/ColoredCircle";
import {
  COLOR_MAP,
  COLOR_MAP_NAMES,
} from "../../../../../../constants/colormapping";
import { margin, minHeight } from "@mui/system";

const overrideStyles = (theme) => ({
  root: {
    backgroundColor: styles.darkBlueSuperLight,
    fontSize: "2rem",
  }
});

const propTypes = {
  color: PropTypes.string,
  classes: PropTypes.object,
  subHeaderName: PropTypes.string,
  firstHeaderInList: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

const CustomizedListSubHeader = ({ 
  color, 
  classes,
  subHeaderName,
  firstHeaderInList = false,
  children
}) => {
  return (
    <>
      <ListSubheader classes={{ root: classes.root }}>
        <div className="container" style={{marginTop: firstHeaderInList ? '0px' : '2.5rem', marginBottom: '1rem'}}>
          {COLOR_MAP[color] && (
            <ColoredCircle
              color={COLOR_MAP[color]}
              name={COLOR_MAP_NAMES[color]}
              size={"xm"}
            />
          )}
         
          {children}
        </div>
      </ListSubheader>
    </>
  );
};

CustomizedListSubHeader.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(
  CustomizedListSubHeader
);
